<!-- 视图 -->
<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <!-- 页面标题 -->
                <div class="Title" style="margin-bottom:10px;">{{ $t('PURCHASE_CONTRACT') }}</div>
            </div>
            <template>
                <el-row v-if="!seaObj.isAdvancedSearch" class="topFromSearch">
                    <el-col class="formDiv" :xs="24" :sm="18" :md="18" :lg="18" :xl="18" style="align-self: flex-end; ">
                        <el-input style="width:550px;" v-model="seaObj.seaCodeVal"
                            :placeholder="$t('PLEASE_ENTER_THE_KEYWORDS_TO_BE_SEARCHED')" @click="searchShowList" >
                        </el-input>
                        <el-badge :value="seaObj.selectBadge" style="margin-right: 18px;">
                            <el-button plain icon="iconfont shaixuan2" size="mini" :title="$t('Display Filter')"
                                @click="seaObj.isAdvancedSearch = true;">{{ $t('Filter') }}
                            </el-button>
                        </el-badge>
                        <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary"
                            @click="searchShowList"> {{ $t('Search') }}</el-button>
                    </el-col>
                    <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6"
                        style="padding-top: 20px!important; text-align: right; align-self: flex-end;">
                        <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain
                            @click="addInfo"> {{ $t('New') }}</el-button>
                    </el-col>
                </el-row>

                <el-row :gutter="20" class="topFromSearch" v-if="seaObj.isAdvancedSearch">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="height: auto;">
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span :style="styleTitleUp(seaObj.seaCodeVal)">{{ $t('Key_Words') }}:</span>
                            <el-input @focus="focusDiv($event)" @blur="blurDiv($event)" v-model="seaObj.seaCodeVal" >
                            </el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span :style="styleTitleUp(seaObj.codeVal)">{{ $t('Contract_NO') }}:</span>
                            <el-input v-model="seaObj.codeVal" @focus="focusDiv($event)" @blur="blurDiv($event)" >
                            </el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span :style="styleTitleUp(seaObj.addressVal)">{{ $t('Signing_Address') }}:</span>
                            <el-input v-model="seaObj.addressVal" @focus="focusDiv($event)" @blur="blurDiv($event)" >
                            </el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                               <span id="TypeSpan"
                                :style="seaObj.typeVal.length == 0 ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{ $t('Contract_Type') }}:</span>
                            <el-select v-model="seaObj.typeVal" @focus="focusDiv($event)" @blur="blurDiv($event)" 
                                 collapse-tags placeholder="" style="width:100%;" @change="Typechang">
                                <el-option v-for="item in this.testData" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span id="venSpan"
                                :style="seaObj.vendorVal.ids.length == 0 ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                        $t('Supplier_')
                                }}:</span>
                            <el-select v-model="seaObj.vendorVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)" 
                                multiple collapse-tags placeholder="" style="width:100%;" @change="venchang">
                                <el-option v-for="item in this.testData" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span :style="styleTitleUp(seaObj.productNameVal)">{{ $t('Product_Name') }}:</span>
                            <el-input v-model="seaObj.productNameVal" @focus="focusDiv($event)" @blur="blurDiv($event)" >
                            </el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span :style="styleTitleUp(seaObj.productCodeVal)">{{ $t('Product_Code') }}:</span>
                            <el-input v-model="seaObj.productCodeVal" @focus="focusDiv($event)" @blur="blurDiv($event)" >
                            </el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span :style="styleTitleUp(seaObj.colorVal)">{{ $t('Color_') }}:</span>
                            <el-input v-model="seaObj.colorVal" @focus="focusDiv($event)" @blur="blurDiv($event)" >
                            </el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span :style="styleTitleUp(seaObj.remarkVal)">{{ $t('Remark') }}:</span>
                            <el-input v-model="seaObj.remarkVal" @focus="focusDiv($event)" @blur="blurDiv($event)" >
                            </el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span :style="styleTitleUp(seaObj.beginTimeVal)">{{ $t('Effective_Start_Date') }}:</span>
                            <el-date-picker class="dataicon" v-model="seaObj.beginTimeVal" type="date"
                                @focus="focusDiv($event)" @blur="blurDiv($event)" format="yyyy-MM-dd" 
                                value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                            <span :style="styleTitleUp(seaObj.endTimeVal)">{{ $t('Effective_End_Date') }}:</span>
                            <el-date-picker class="dataicon" v-model="seaObj.endTimeVal" type="date"
                                @focus="focusDiv($event)" @blur="blurDiv($event)" format="yyyy-MM-dd" 
                                value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                    </el-col>

                </el-row>
                <el-row :gutter="20" class="topFromSearch" v-if="seaObj.isAdvancedSearch">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                        style="text-align: right; align-self: flex-end;">
                        <el-button plain icon="iconfont shaixuan1" size="mini" :title="$t('Hidden Filter')"
                            @click="seaObj.isAdvancedSearch = false; getSelectBadge()"> {{ $t('Hidden Filter') }}
                        </el-button>
                        <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary"
                            @click="searchShowList"> {{ $t('Search') }}</el-button>
                        <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain
                            @click="addInfo"> {{ $t('New') }}</el-button>
                    </el-col>
                </el-row>
                <avue-crud :option="setData.tableOpt" :data="infoListData" :row-style="rowStyle" :page.sync="page"
                    @current-change="currentChange">
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')"
                            @click="editInfo(row)"></el-button>
                        <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')"
                            @click="delInfo(row)"></el-button>
                    </template>
                    <template slot="expand" slot-scope="props">
                        <el-table :data="props.row.itemDetails" :header-cell-style="tableHeaderColor">
                            <el-table-column type="index"></el-table-column>
                            <el-table-column prop="ProductCode" :label="$t('PRODUCT_CODE')"></el-table-column>
                            <el-table-column prop="ProductName" :label="$t('PRODUCT_NAME')"></el-table-column>
                            <el-table-column prop="Qty" :label="$t('QUANTITY')"></el-table-column>
                            <el-table-column prop="Uom" :label="$t('UNIT')"></el-table-column>
                            <el-table-column prop="Color" :label="$t('COLOR_')"></el-table-column>
                            <el-table-column prop="Rate" :label="$t('UNIT_PRICE')"></el-table-column>
                            <el-table-column prop="TotalTaxesAndCharges" :label="$t('TOTAL_TAX')"></el-table-column>
                            <el-table-column prop="Amount" :label="$t('TOTAL_PRICE')"></el-table-column>
                        </el-table>
                    </template>
                </avue-crud>
            </template>
        </basic-container>
    </div>
</template>
<!-- 样式 -->
<style>
</style>
<!-- 逻辑js -->
<script>
//引入对象
import { PurchaseContractIList,  PurchaseContractIDel } from "@/api/Purchase/PurchaseContractApi";
//输出对象
export default {
    //data：返回实体对象
    data() {
        return {
            //高级查询对象
            seaObj: {
                seaCodeVal: '', selectBadge: null, isAdvancedSearch: false,
                //其他查询字段
                codeVal: '', addressVal: "", typeVal: "", productNameVal: '', productCodeVal: '', vendorVal: { ids: [], }, beginTimeVal: '', endTimeVal: '', colorVal: '', remarkVal: '',
            },
            //分页对象
            page: { currentPage: 1, pageSize: 10, total: 0, layout: "total,pager,prev, next", background: true },
            //数据集对象
            infoListData: [],
            testData: [
                {
                    value: '测试选项1',
                    label: '测试选项1',
                    num: 1
                }, {
                    value: '测试选项2',
                    label: '测试选项2',
                    num: 2
                }, {
                    value: '测试选项3',
                    label: '测试选项3',
                    num: 3
                }, {
                    value: '测试选项4',
                    label: '测试选项4',
                    num: 4
                }, {
                    value: '测试选项5',
                    label: '测试选项5',
                    num: 5
                }],
        }
    },
    //获取(dom✓/data✓/methods✓) computed:计算属性，是根据依赖关系进行缓存的计算，只有在它的相关依赖发生改变时才会进行更新
    computed: {
        setData() {
            return {
                tableOpt: {
                    border: false,              //表格边框是否显示
                    menu: true,                //是否显示操作栏
                    page: true,
                    simplePage: true,
                    refreshBtn: false, //表格上面小的 刷新按钮，默认false
                    columnBtn: false,  //表格上面小的 列表按钮，默认false
                    addBtn: false,      //是否显示添加按钮，默认true
                    editBtn: false,     //是否显示编辑按钮，默认true
                    delBtn: false,      //是否显示删除按钮，默认true
                    emptyText: this.$t('No Data'),  //表格无数据时
                    menuTitle: this.$t('OPERATION'),//菜单栏文本
                    expand: true,
                    rowKey: 'Id',
                    expandRowKeys: [],
                    // selection: true,
                    // dialogClickModal: false,
                    // tip: false,
                    // expand: true,
                    // rowKey: 'Id',
                    // expandRowKeys: [],
                    // searchShow: true,          //首次加载是否显示搜索
                    // searchMenuSpan: 4,         //搜索按钮长度
                    // searchSpan:6,              //搜索框长度  最大长度24
                    // index: true,               //是否显示序号
                    // card: true,                 //是否列表公用
                    // tabs: true,                 //开启选项卡
                    // tabsActive: 3,              //选项卡个数
                    // gutter: 132,                //间距
                    // header: true,
                    // calcHeight: 30,             //表格高度差（主要用于减去其他部分让表格高度自适应）    
                    // height:'auto',              //表格高度    
                       align: 'center',            //文本内容 
                    // labelWidth:120,             //表单前面的标题长度
                    // labelPosition:'top',        //弹窗编辑页表标题位置
                       menuWidth:120,              //按钮宽度
                    // menuAlign:'center',         //按钮位置
                    // menuHeaderAlign:'center',   //头位置
                    // mockBtn: false,    //是否开启模拟按钮，默认false
                    // emptyBtn: false,   //是否显示清空按钮，默认true
                    // submitBtn: false,  //是否显示提交按钮，默认true   
                    // searchBtn: false,  //表格上面小的 搜索按钮，默认false
                    // excelBtn:false,    //表格导出按钮是否显示 ，默认false
                    // saveBtn: false,    //弹窗保存按钮 ，默认true
                    // cancelBtn: false,  //弹窗取消按钮 ，默认true
                    // viewBtn: false,    //是否显示查看按钮，默认false
                    // submitText: '',                 //提交按钮文本
                    // editTitle: this.$t('Edit'),     //编辑按钮文本
                    // addTitle: this.$t('Add'),       //新增按钮文本
                    // indexLabel: ' ',                //序号栏文本
                    column: [
                        //    {
                        //         label: "用户名",                            //标签名称
                        //         prop: "username",                          //值
                        //         type: "cascader",                          //input类型select下拉、cascade级联、tree树 //select | radio | checkbox | date 默认为text
                        //         multiple:true,                             //是否可以选择多个，和select一起使用
                        //         tip: '这是信息提示',                        //内容提示
                        //         tipPlacement: 'left',                      //内容提示语位置
                        //         labelWidth: 0,
                        //         labelTip: '我是一个标题提示语',              //标签提示语
                        //         labelTipPlacement: 'right',                //标签提示语位置
                        //         labelPosition: 'top',                      //标签位置
                        //         maxlength: 3,                              //最长
                        //         suffixIcon: 'el-icon-tickets',             //后图标
                        //         prefixIcon: 'el-icon-tickets',             //前图标
                        //         format: 'yyyy-MM-dd',                      //时间显示格式化
                        //         valueFormat: 'yyyy-MM-dd',                 //返回值时间格式化
                        //         startPlaceholder: '时间日期开始范围自定义',
                        //         endPlaceholder: '时间日期结束范围自定义',
                        //         minlength: 2,                              //最短
                        //         row: true,                                 //独占一行
                        //         prepend: 'http://',                        //后缀
                        //         append: 'com',                             //前置，
                        //         minRows: 8,                                //文本框最小行
                        //         maxRows: 10,                               //文本框最大行
                        //         showWordLimit: true,                       //显示剩余多少长度
                        //         step: 2,                                   //增加步数
                        //         stepStrictly: true,                        //严格步数
                        //         precision: 2,                              //小数点精度
                        //         controlsPosition: '',                      //步数增加按钮位置
                        //         dataType: 'number',                        //数据类型
                        //         span: 24,                                  //宽度
                        //         className: 'formClassName',                //样式
                        //         value: '默认值',                           //默认值
                        //         width: "150",                             //表格的宽度
                        //         fixed: true,                              //是否冻结列
                        //         drag: true,                               //是否可以拖拽select中的结果类容
                        //         all: true,                                //多选框时，是否全选
                        //         border: true,                             //空心多选框
                        //         min: 1,                                   //限制选择数量
                        //         max: 2,
                        //         button: true,                             //按钮类型多选框
                        //         addDisplay: false,                        //新增时是否显示
                        //         editDisplay: false,                       //编辑时是否显示
                        //         viewDisplay: true,                        //详情时是否显示
                        //         hide: true,                               //表单查询时是否显示
                        //         display: true,                            //在查看，新增，编辑页面是否显示
                        //         span: 24,                                 //24一条数据占一行，8一行3条数据
                        //         addDisabled: true,                        //添加的时候不能修改
                        //         editDisabled: true,                       //编辑的时候不能修改
                        //         sortable:true,                            //排序方式切换，倒序、正序切换
                        //         slot: true,                               //加一个插槽 子表可以放这里
                        //         visdiplay:true,                           //表单不显示
                        //         overHidden: true,                         //超出省略号显示
                        //         click,
                        //         focus,
                        //         blur,                                     //事件函数
                        //         errorslot: true,                          //错误自定义卡槽
                        //         labelslot: true,                          //标签自定义卡槽
                        //         formslot: true,                           //内容自定义卡槽
                        //         addDisplay: false,
                        //         dicData:[]]                               //传入需要引用的字典  //type的数据字典,当type为：select | radio | checkbox 加载
                        //         dicUrl: `${baseUrl}/getProvince`,        //网络字典URL
                        //         dataDetail: val => {
                        //             return ``;;//是否对列表数据处理
                        //         },
                        //         rules: [{                               //表单校验规则
                        //             required: true,   //是否必填
                        //             message: "请输入用户名",  //提示信息 
                        //             trigger: "blur" }] 
                        //     },
                        {
                            label: this.$t('SUPPLIER_'),
                            prop: 'SupplierName',
                        },
                        {
                            label: this.$t('CONTRACT_NO'),
                            prop: 'Code',
                        },
                        {
                            label: this.$t('SIGNING_ADDRESS'),
                            prop: 'SignAddress',
                        },
                        {
                            label: this.$t('CONTRACT_TYPE'),
                            prop: 'Type',
                        },
                        {
                            label: this.$t('TOTAL'),
                            prop: 'Total',
                        },
                        {
                            label: this.$t('CURRENCY'),
                            prop: 'CurrencyName',
                        },
                        {
                            label: this.$t('SIGNING_DATE'),
                            prop: 'SignDate',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: this.$t('EFFECTIVE_START_DATE'),
                            prop: 'BeginDate',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: this.$t('EFFECTIVE_END_DATE'),
                            prop: 'EndDate',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: this.$t('REMARKS'),
                            prop: 'Remark',
                        }
                    ]
                },
            }
        }
    },
    //beforeCreate（创建前）:获取(dom×/data×/methods×)--> created（创建后）:获取(dom×/data✓/methods✓)-->  beforeMount（挂载前）:获取(dom×/data✓/methods✓)--> mounted（挂载后）:获取(dom✓/data✓/methods✓)-->...
    //created：data、mounted、watch等已经完成初始化，但是el dom树还未挂载
    created() {
        this.init()
    },
    //watch:用于监听data里面的数据是否被修改，一旦修改就可以执行一些其他的操作
    watch: {
        //监听路由对象是否变化
        $route() {
            this.init()
        }
    },
    //methods:方法函数
    methods: {
        //初始加载数据
        init() {
            this.getShowList();
        },
        //删除
        delInfo(row) {
            this.$confirm(this.$t('IsDel'+" : "+row.Code), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: "warning"
            }).then(() => {
                PurchaseContractIDel(row.Id).then(() => {
                    this.getShowList();
                    this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                });
            });
        },
        //修改
        editInfo(row) {
            this.$router.push({ path: '/textile_Purchase/PO_ContractAdd', query: { sign: 'edit', ID: row.Id } })
        },
        //新增
        addInfo() {
            this.$router.push({ path: '/textile_Purchase/PO_ContractAdd', query: { sign: 'add', ID: '' } })
        },
        //搜索
        searchShowList() {
            this.getShowList();
        },
        //获取展示主列表
        getShowList() {
            let queryCondition = {
                searchText: this.seaObj.seaCodeVal,
                Code: this.seaObj.codeVal,
                Address: this.seaObj.addressVal,
                Type: this.seaObj.typeVal,
                Supplier: this.seaObj.vendorVal.ids.toString(),
                BeginDate: this.seaObj.beginTimeVal,
                EndDate: this.seaObj.endTimeVal,
                ProductCode: this.seaObj.productCodeVal,
                ProductName: this.seaObj.productNameVal,
                Color: this.seaObj.colorVal,
                Remark: this.seaObj.remarkVal,
                page: this.page.currentPage,
                pageSize: this.page.pageSize,
            };
            //查询后台数据，绑定数据集及分页   
            PurchaseContractIList(queryCondition).then(res => {
                this.infoListData = res.data.PurchaseContracts;
                this.page.total = res.data.TotalCount;
                this.page.pageSize = res.data.PageSize;
            }).catch(erro => { console.log(erro) })
        },
        //切换分页
        currentChange(val) {
            this.page.currentPage = val
            this.getShowList();
        },
        //选中值改变--类型
        Typechang() {
            if (this.typeVal="") {
                document.querySelector("#TypeSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
            } else {
                document.querySelector("#TypeSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
            }
        },
        //选中值改变--供应商
        venchang() {
            if (this.vendorVal.ids.length === 0) {
                document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
            } else {
                document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
            }
        },
        //选中值改变--状态
        statuschang() {
            if (this.otherStatusVal === 0) {
                document.querySelector("#otherStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
            } else {
                document.querySelector("#otherStatusSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
            }
        },
        //其他搜索条件已输入合计
        getSelectBadge() {
            this.seaObj.selectBadge = 0;
            if (this.seaObj.productCodeVal != "") {
                ++this.seaObj.selectBadge;
            };
            if (this.seaObj.productNameVal != "") {
                ++this.seaObj.selectBadge;
            };
            if (this.seaObj.colorVal != "") {
                ++this.seaObj.selectBadge;
            };
            if (this.seaObj.remarkVal != "") {
                ++this.seaObj.selectBadge;
            };
            if (this.seaObj.codeVal != "") {
                ++this.seaObj.selectBadge;
            };
            if (this.seaObj.addressVal != "") {
                ++this.seaObj.selectBadge;
            };
            if (this.seaObj.typeVal != "") {
                ++this.seaObj.selectBadge;
            };
            if (this.seaObj.beginTimeVal != "") {
                ++this.seaObj.selectBadge;
            };
            if (this.seaObj.endTimeVal != "") {
                ++this.seaObj.selectBadge;
            };
            if (this.seaObj.vendorVal.ids.length != 0) {
                ++this.seaObj.selectBadge;
            };

            if (this.seaObj.selectBadge == 0) {
                this.seaObj.selectBadge = null;
            }
        },
        //搜索栏样式（已输入）--标题上移
        styleTitleUp(e) {
            var textColumn = e;
            if (textColumn == '' || textColumn == null) {
                return 'top: 50%;left: 50%;transform: translate(-50%, -50%);';
            } else {
                return 'top: -10px;left: 20px;transform: translate(0, 0);';
            }
        },
        //搜索栏获取焦点
        focusDiv(obj) {
            if (obj.currentTarget === undefined) {
                obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
            } else {
                if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                    obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                } else {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }

            }
        },
        //搜索框失去焦点
        blurDiv(obj) {
            console.log("obj.currentTarget", obj.currentTarget);
            if (obj.currentTarget === undefined) {
                if (obj.value === "") {
                    obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                }
            } else if (obj.currentTarget === null) {
                if (this.vendorVal.ids.length === 0) {
                    document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                }
                if (this.otherStatusVal == null || this.otherStatusVal == '') {
                    document.querySelector("#otherStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                }
                if (this.traingStatusVal == null || this.traingStatusVal == '') {
                    document.querySelector("#traingStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                }
            } else {
                if (obj.target.value == "") {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                }
            }
        },
        //设置表头行的样式
        tableHeaderColor() {
            return 'background-color:#838bb3!important;padding: 0!important;'

        },
        //表体颜色
        rowStyle(rowIndex) {
            if (rowIndex % 2 === 0) {
                return {
                    backgroundColor: '#F8F8F8'
                }
            }
        },
    }
}
</script>